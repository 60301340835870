import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const BlogIndex = ({ data }) => (
  <Layout>
    <SEO title="Blog Posts" />
    <h1>Blog Index</h1>
    {data.allMarkdownRemark.edges.map(post => (
      <div key={post.node.id}>
        <h3>
          <Link to={post.node.frontmatter.path}>
            {post.node.frontmatter.title}
          </Link>
        </h3>
        <small>
          <i className="far fa-calendar" /> {post.node.frontmatter.date}
        </small>
        <p>{post.node.frontmatter.excerpt}</p>
        <hr />
      </div>
    ))}
  </Layout>
)

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(filter: {frontmatter: {path: {regex: "/blog/"}}}, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            author
            excerpt
          }
        }
      }
    }
  }
`

export default BlogIndex
